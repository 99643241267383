.ais-Pagination-link {
  border: none;
  font-weight: 700 !important;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  height: 32px;
  color: $gray-6 !important;

  svg {
    width: 12px;
  }

  &:hover {
    text-decoration: none;
  }
}

.media-container-upload-modal,
.upload-form {
  .ant-upload.ant-upload-select {
    width: 100%;
  }

  .ant-upload-list-item.ant-upload-list-item-done {
    height: 36px !important;
    padding-left: 5px;
    padding-right: 5px;
  }

  button[title="Remove file"] {
    width: 30px !important;
    height: 30px !important;
    border-radius: 50% !important;

    .anticon.anticon-delete {
      color: #ff4d4f !important;
    }

    &:hover {
      background-color: #ff787529 !important;
    }
  }
}

.str-chat__message-input .str-chat__send-button svg path,
.str-chat__message .str-chat__message-status svg path,
.apexcharts-canvas .apexcharts-zoom-icon.apexcharts-selected svg,
.str-chat__message .str-chat__message-options .str-chat__message-reactions-button:active .str-chat__message-action-icon path {
  fill: #00a742 !important;
}

.str-chat__message-input .str-chat__send-button:disabled svg path {
  fill: #b4b7bb !important;
}

// CHAT SEND BUTTON TOOLTIP
.str-chat__message-input .str-chat__send-button {
  position: relative;
}

.str-chat__message-input .str-chat__send-button::before,
.str-chat__message-input .str-chat__send-button::after {
  text-transform: none;
  font-size: 0.9em;
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}

.str-chat__message-input .str-chat__send-button::before {
  content: '';
  border: 5px solid transparent;
  z-index: 1001;
}

.str-chat__message-input .str-chat__send-button::after {
  content: 'Send';
  max-width: 21em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1000;
  min-width: 40px;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  padding: 6px 8px;
  color: #fff;
  text-align: start;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(35, 37, 66, 0.85);
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  display: flex;
}

.chat__content.-es,
.-es {
  .str-chat__message-input .str-chat__send-button::after {
    content: 'Enviar';
  }
}

.str-chat__message-input .str-chat__send-button:hover::before,
.str-chat__message-input .str-chat__send-button:hover::after {
  display: flex;

  @include sm {
    display: none;
  }
}

/* Tooltip arrow for upward direction */
.str-chat__message-input .str-chat__send-button::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: rgba(35, 37, 66, 0.85);
  right: 20px !important;
  transform: translateX(50%);
  /* Adjust arrow position */
}

.str-chat__message-input .str-chat__send-button::after {
  bottom: calc(100% + 5px);
  right: 0;
  /* Align tooltip to the right */
  transform: translateX(0);
  /* Remove center alignment */
}

/* Positioning adjustments */
.str-chat__message-input .str-chat__send-button::before,
.str-chat__message-input .str-chat__send-button::after {
  right: 10px;
  /* Align tooltip to the right */
  transform: translateX(0);
  /* Remove center alignment */
}

.str-chat__message-input .str-chat__send-button,
.str-chat__message-input .str-chat__send-button {
  &:disabled {

    &::after,
    &::before {
      display: none;
    }
  }
}

// CHAT EMOJI BUTTON TOOLTIP
.str-chat__message-textarea-emoji-picker {
  position: relative;
}

.str-chat__message-textarea-emoji-picker::before,
.str-chat__message-textarea-emoji-picker::after {
  text-transform: none;
  font-size: 0.9em;
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}

.str-chat__message-textarea-emoji-picker::before {
  content: '';
  border: 5px solid transparent;
  z-index: 1001;
}

.str-chat__message-textarea-emoji-picker::after {
  content: 'Emoji';
  max-width: 21em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1000;
  min-width: 40px;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  padding: 6px 8px;
  color: #fff;
  text-align: start;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(35, 37, 66, 0.85);
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  display: flex;
  font-size: 14px !important;
}

.str-chat__message-textarea-emoji-picker:hover::before,
.str-chat__message-textarea-emoji-picker:hover::after {
  display: flex;

  @include sm {
    display: none;
  }
}

/* Tooltip arrow for upward direction */
.str-chat__message-textarea-emoji-picker::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: rgba(35, 37, 66, 0.85);
  right: 10px !important;
  transform: translateX(50%);
  /* Adjust arrow position */
}

.str-chat__message-textarea-emoji-picker::after {
  bottom: calc(100% + 5px);
  right: -10px !important;
  /* Align tooltip to the right */
  transform: translateX(0);
  /* Remove center alignment */
}

/* Positioning adjustments */
.str-chat__message-textarea-emoji-picker::before,
.str-chat__message-textarea-emoji-picker::after {
  right: 0px;
  /* Align tooltip to the right */
  transform: translateX(0);
  /* Remove center alignment */
}

// CHAT ATTACHMENTS BUTTON TOOLTIP
.str-chat__file-input-container {
  position: relative;
  /* Required to position the tooltip correctly */
}

.str-chat__file-input-container::before,
.str-chat__file-input-container::after {
  text-transform: none;
  font-size: 0.9em;
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}

.str-chat__file-input-container::before {
  content: '';
  border: 5px solid transparent;
  z-index: 1001;
}

.str-chat__file-input-container::after {
  content: 'Add attachments';
  max-width: 21em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1000;
  min-width: 40px;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  padding: 6px 8px;
  color: #fff;
  text-align: start;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(35, 37, 66, 0.85);
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
}

.chat__content.-es,
.-es {
  .str-chat__file-input-container::after {
    content: 'Agregar archivos adjuntos';
  }
}

.str-chat__file-input-container:hover::before,
.str-chat__file-input-container:hover::after {
  display: flex;

  @include sm {
    display: none;
  }
}

/* Tooltip arrow for upward direction */
.str-chat__file-input-container::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: rgba(35, 37, 66, 0.85);
  left: 18px !important;
}

.str-chat__file-input-container::after {
  bottom: calc(100% + 5px);
  left: 0;
  /* Align tooltip to the left */
  transform: translateX(0);
  /* Remove center alignment */
}

/* Positioning adjustments */
.str-chat__file-input-container::before,
.str-chat__file-input-container::after {
  left: 0;
  /* Align tooltip to the left */
  transform: translateX(0);
  /* Remove center alignment */
}

/* Animation keyframes */
@keyframes tooltips-vert {
  to {
    opacity: 0.9;
    transform: translate(0, 0);
    /* Adjust for left alignment */
  }
}

@keyframes tooltips-horz {
  to {
    opacity: 0.9;
    transform: translate(0, -50%);
  }
}

/* Apply animation on hover */
.str-chat__message-input .str-chat__send-button:hover::before,
.str-chat__message-input .str-chat__send-button:hover::after,
.str-chat__message-textarea-emoji-picker:hover::before,
.str-chat__message-textarea-emoji-picker:hover::after,
.str-chat__file-input-container:hover::before,
.str-chat__file-input-container:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}

.ant-btn-link {
  color: #00a742 !important;
}

.ant-message {
  z-index: 999999 !important;
}

.ant-avatar {
  background-color: #f1f3f4 !important;
}

.ant-input-affix-wrapper:not(.ant-input-textarea-affix-wrapper) {
  height: 40px;
}

.ant-layout-sider-zero-width-trigger {
  top: 5px !important;
  z-index: 100 !important;
}

.ant-avatar.ant-avatar-circle.ant-avatar-icon {
  display: flex;
  align-content: center;
  justify-content: center;
  border: none;
}

.ant-avatar-group-popover {
  .ant-popover-inner-content {
    display: flex;
    flex-direction: row;
  }
}

.ant-popover {
  max-width: 500px !important; /* Set your preferred width */
  min-width: 200px;
}

.ant-message-custom-content {
  display: flex !important;
}

.ant-pagination-item-link {
  height: 100%;
  display: flex;
  align-items: center;
}

.ant-checkbox-group,
.ant-radio-group {
  display: flex;
  flex-direction: column;
}

.ant-checkbox-group {
  .ant-checkbox-wrapper {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.filter-menu__item.-results {
  .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
    background-color: #fff !important;
  }
}

.ais-Pagination-link--selected {
  background: $gray-6;
  border-radius: 50%;
  color: $gray-1 !important;
  min-width: 32px;
  height: 32px;
}

.ais-Pagination-item.ais-Pagination-item--disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  opacity: 0.3;
  cursor: not-allowed;
}

.opportunities__right .ais-Pagination-item--nextPage,
.opportunities__right .ais-Pagination-item--previousPage {
  background: transparent !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.profile-drawer__container {
  .profile-drawer__instagram {
    overflow-x: hidden;
  }
}

.has-scroll {
  *::-webkit-scrollbar {
    display: block !important;
  }

  *::-webkit-scrollbar {
    background-color: $gray-1;
    width: 16px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: $gray-4;
    border-radius: 16px;
    border: 5px solid $gray-1;
  }
}

.view-link {
  color: #19202B !important;
  text-decoration: none !important;

  &:hover {
    color: #19202B !important;
  }
}

.ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger-left {
  border-left: 1px solid rgba(5, 5, 5, 0.06) !important;
}

.ant-empty-image {
  height: 150px !important;
  margin-bottom: 24px !important;
}

.filter-menu {
  &__title {
    svg {
      color: $gray-4;
    }
  }

  &__footer {
    padding-top: 24px;
    margin-top: 24px;
    position: relative;
    margin-bottom: -16px;

    &::before {
      content: '';
      width: 130%;
      height: 1px;
      background: $gray-3;
      position: absolute;
      left: -40px;
      top: 0;

      @include sm {
        width: calc(100% + 48px);
        left: -24px;
      }
    }

    @include sm {
      margin-bottom: 0;
    }
  }
}

.report__table-btn {
  height: 40px;
  width: 165px;
  margin-top: -8px;
}

#apexchartsqkpg8ecr {
  font-family: 'Open Sans';
}

.slick-arrow {
  &::before {
    content: none !important;
  }
}

.icon-other {
  cursor: pointer;
}

.css-1pahdxg-control:hover {
  border-color: $color-3 !important;
}

.css-1n7v3ny-option {
  background: $gray-3 !important;
}

.-has-border {
  border-bottom: 1px solid $gray-4 !important;
}

.img-crop-modal .img-crop-container {
  height: 33vh !important;
}

.ant-carousel {
  .slick-slide {
    img {
      object-fit: cover;
    }
  }

  .slick-dots {
    li {
      button {
        background: $gray-4 !important;
      }
    }

    .slick-active {
      button {
        background: $color-5 !important;
      }
    }
  }
}

.search-creators__tabs {
  &-btn {
    &:hover:not(.-is-active) {
      svg {
        path {
          fill: #00a742 !important;
        }
      }
    }
  }
}

.VizExNotificationBadge__Wrapper-ndrp3p-0.cxUnaC {
  @include sm {
    display: none !important;
  }
}

.IconLauncher__BaseLauncher-la9x37-0.IconLauncher__CircleLauncher-la9x37-2.gOIIGo.reagan--widget-loaded,
.VizExNotificationBadge__Wrapper-ndrp3p-0 .cxUnaC,
.Application__WidgetAppContainer-sc-1f2l0a1-0.dvBAhE.widget-app-container {
  @include sm {
    display: none !important;
  }
}

// .rfu-file-upload-button--disabled {
//   label {
//     cursor: not-allowed;
//   }
// }

.report__table {
  margin-top: 20px;

  &-fixed {
    margin-top: 140px;
  }

  &-no-campaign-blankslate {
    font-size: 12px;

    &__mobile {
      position: fixed;
      top: 0;
      left: 0;
      padding: 0 10px;
    }

    &__title {
      font-size: 20px !important;
      margin-bottom: 16px !important;
    }

    &__title p {
      font-size: 18px !important;
      margin-bottom: 16px !important;
    }

    &__link {
      text-decoration: underline;
      cursor: pointer;
      font-weight: bold;
    }

    &__text {
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
      display: inline-block;
      font-size: 14px !important;

      span {
        text-decoration: underline !important;
        color: $color-3 !important;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
          color: #3dbf69 !important;
        }
      }
    }
  }
}

.react-multiple-carousel__arrow {
  z-index: 998 !important;
}

.ant-table-wrapper .ant-table-row-expand-icon:hover,
.ant-table-wrapper .ant-table-row-expand-icon-cell .ant-table-row-expand-icon {
  color: #19202b;
}

@keyframes progress-bar-shine {
  to {
    transform: translateX(0);
    opacity: 0.1;
  }
}

.ant-tooltip-inner {
  min-height: auto !important;
}

.fixed-modal-footer {
  .ant-modal-footer {
    position: sticky;
    bottom: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    background: #fff;
    border-top: 1px solid #e8ebec;
  }
}

button:hover .ql-stroke,
.ql-picker-label:hover .ql-stroke {
  fill: none;
  stroke: $color-3 !important;
}

.ql-active .ql-stroke {
  fill: none;
  stroke: $color-3 !important;
}

button:hover .ql-fill,
.ql-picker-label:hover .ql-fill {
  fill: $color-3 !important;
  stroke: none;
}

.ql-active .ql-fill {
  fill: $color-3 !important;
  stroke: none;
}

.ql-snow.ql-toolbar button:focus {
  color: $color-3 !important;
}

.ant-tour {
  @include sm {
    width: auto;
  }
}

.ant-table-content .ant-table-body {
  overflow-x: scroll !important;
  overflow-y: hidden !important;
  scrollbar-width: thick !important;
  /* For Firefox */
}

.ant-table-content {
  .ant-table-body {
    &::-webkit-scrollbar {
      background-color: $gray-1 !important;
      width: 16px !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1 !important;
      border-radius: 16px !important;
      border: 5px solid #c1c1c1 !important;
    }
  }
}

.ant-table-column-has-sorters {
  cursor: pointer !important;
}

.ant-tag {
  font-weight: 500;
}

.media {
  &__col {
    width: 100%;

    .control-arrow:before {
      border-left-color: #000 !important;
    }

    .control-prev.control-arrow:before {
      border-right-color: #000 !important;
    }

    .ant-card-body {
      border-radius: 0 !important;
    }

    .ant-card {
      border-radius: 10px;
      overflow: hidden;
    }

    &:hover {

      .ant-card-body,
      .ant-card-actions,
      .ant-card-cover {
        background-color: #f3fbf7 !important;
      }
    }

    &.-is-selected {

      .ant-card-body,
      .ant-card-actions,
      .ant-card-cover {
        background-color: rgb(217, 242, 230) !important;
      }
    }

    @media screen and (min-width: 620px) {
      width: 50%;
      min-width: 50%;
    }

    @media screen and (min-width: 950px) {
      width: 33.33%;
      min-width: 33.33%;
    }

    @media screen and (min-width: 1150px) {
      width: 25%;
      min-width: 25%;
    }

    @media screen and (min-width: 1460px) {
      width: 20%;
      min-width: 20%;
    }
  }
}

.custom-datepicker {
  @media screen and (max-height: 820px) {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    z-index: 1050;
  }

  @media screen and (min-height: 821px) and (max-width: 768px) {
    position: fixed !important;
    top: 42% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    z-index: 1050;
  }
}

@media screen and (max-width: 960px) {
  button.btn-search {
    margin-top: 16px;
    margin-left: 0 !important;
    margin-bottom: 16px !important;
  }

  // .search-bar__networks {
  //   margin-bottom: 16px !important;
  // }
}

.ant-menu-dark {
  .ant-menu-sub {
    background-color: #001529 !important;
  }
}

a.menu-link {
  color: inherit !important;
  text-decoration: none !important;
}

.menu-link__btn {
  color: rgba(255, 255, 255, 0.65) !important;
  border-color: rgba(255, 255, 255, 0.65) !important;

  &:hover {
    color: #fff !important;
    border-color: #fff !important;
  }
}

.custom-lang-menu-popup {
  min-width: 200px !important;
}

.custom-lang-menu-popup2 {
  min-width: 240px;
}

.ant-tabs-tab:not(.ant-tabs-tab-active) {
  &:hover {
    div {
      color: #00a742 !important;
      transition: color 0.3s ease-in-out;
    }

    svg {
      path {
        fill: #00a742 !important;
        transition: fill 0.3s ease-in-out;
      }
    }
  }
}

.search-creators__dropdown.-not-mobile {
  @media screen and (max-height: 670px) {
    inset: 40px auto auto 0px !important;
  }
}

.reporting,
.pipelines,
.lists,
.settings-container.-is-brand {
  .ant-menu-item-selected {
    background-color: #d9f2e6 !important;
  }

  .ant-menu-item:not(.ant-menu-item-selected) {
    &:hover {
      background-color: #F3FBF7 !important;
    }
  }
}

.settings-container.-is-brand {
  .ant-layout {
    width: 100%;
  }
}

.upload-files-mobile {
  &:hover {
    color: rgba(35, 37, 66, 0.88) !important;
    border-color: rgb(217, 217, 217) !important;
  }
}

.ant-upload-drag-hover {
  opacity: 0.5 !important;
  border-width: 2px !important;
  border-style: solid !important;
}

.ant-btn-text.ant-btn-dangerous:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #ff787529 !important;
}

[data-icon="info-circle"] {
  cursor: pointer;
}

.ant-modal {
  animation-duration: 0.1s !important;
}

.ant-modal-mask {
  animation-duration: 0.1s !important;
}

.removing-div {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.removing {
  opacity: 0;
  transform: translateX(-100%);
  pointer-events: none;
}